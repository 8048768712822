import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../components/Layout'

import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import FAQs from '../components/FAQs'

import { Box, Flex } from 'grid-styled'
import CTA from '../components/CTA'
import GalleryContainer from '../components/GalleryContainer'
import GalleryImage from '../components/GalleryImage'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
  info
} from '.'
import Form from '../components/Form'
import { Text, GallerySection, Content } from '../components/styles'
import Button from '../components/Button'
import ContentCard from '../components/ContentCard'
import Hero from '../components/Hero'
import { Video } from '../components/procedures'

export default ({ data }) => (
  <Layout header="light">
    <Helmet>
      <title>
        Patio Glass Room Enclosures | Brisbane & Sunshine Coast | Just Patios
      </title>
      <meta
        name="description"
        content="Just Patios builds & installs glass sunroom enclosures in Brisbane & Sunshine Coast. Our glass porch enclosures are customised to your needs and lifestyle. Call us to learn more about our services & get a free design & quote."
      />
      <meta name="keywords" content="Patio Extensions" />
    </Helmet>

    <HomeHeader slant={false} height="75vh">
      <figure>
        <Img fluid={data.jpBackground.childImageSharp.fluid} />
      </figure>
      <div>
        <h2>Glass Room Enclosures</h2>
        <Text size="large">
          Our glass room enclosures are customised to your needs and lifestyle.
          Contact our team at Just Patios to get your free design and quote
          today.
        </Text>
        <Link to="/contact-us/">
          <Button>Contact us today</Button>
        </Link>
      </div>
      <FormContainer>
        <div>
          <Form title="Request Free Quote" showMessage={true} />
        </div>
      </FormContainer>
    </HomeHeader>

    <Content style={{ paddingTop: 100 }}>
      <h1>
        Glass Room Enclosures in Brisbane <i>&amp;</i> The Sunshine Coast
      </h1>

      <h4>
        You can trust our team to build enclosures that will allow you to enjoy
        a functional, stylish and well-designed space.
      </h4>
      <p>
        Is your family getting bigger and in need of more space? Maybe you want
        a bar area or just a sunroom to relax in. We can help you extend your
        home giving you that extra space you need. Add value to your home with
        our quality professionally installed{' '}
        <Link to="/patio-builders-brisbane">glass enclosures for patios</Link>.
      </p>

      <p>
        Here at Just Patios, we offer a wide range of patio enclosures and
        extensions that perfectly accommodate the needs of a growing family. Our
        team understands that it’s not just about providing more space; it’s
        about giving you an area you can actually use.{' '}
      </p>
      <h4>Why Use Enclosures? </h4>
      <p>
        While it’s great to have a lot of space, it can be challenging to make
        the best use of it, especially if the space lacks functional features.
        Glass porch enclosures are a means to enhance the functionality of your
        outdoor spaces and they work amazingly well as entertaining and
        receiving areas.
      </p>

      <p>
        Glass is a popular material for enclosures. As well as lending a fresh
        and elegant look to the space, it also keeps the area cool and maximises
        light.
      </p>

      <p>
        With the help of one of our experienced professional sales
        representatives here at Just Patios we can turn your dream into reality.
        When building your new Glass enclosure we will use only quality
        warranty-backed materials giving you the peace of mind and wow-factor
        you've been looking for.
      </p>

      <Link to="/contact-us/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <AlternatingContent>
      <h2>
        Our <i>Glass Room Enclosure</i> Range &amp; Designs
      </h2>
      <ContentCard
        to="/room-enclosures-brisbane/glass-enclosures"
        image={data.patiosBrisbane.childImageSharp}
        title="Glass Room Enclosures"
        text="Perfect all year round entertainment area for the whole family. With your new Glass Enclosure we can easily match existing house colours giving your home a fresh new look. We have a huge range of glass windows, glass sliding doors and glass swing door configurations available to meet your needs."
        buttons={[
          {
            to: '/room-enclosures-brisbane/glass-enclosures',
            text: 'Learn more'
          }
        ]}
      />
    </AlternatingContent>

    <Hero bgImage={data.blurredHero.childImageSharp} height="550px">
      <h3>Want to find our more?</h3>
      <p>
        Call 1300 284 531 or complete the enquiry form to contact us today and
        find out how we can help.
      </p>
      <Flex>
        <Button color={'#fff'} small={true} outline={true}>
          Contact Us
        </Button>
      </Flex>
    </Hero>

    <GallerySection>
      <h2>Some of Our Recent Projects</h2>
      <p>
        Here at Just Patios, we do more than just build patios. We create
        structures that fit your lifestyle. If you require a patio for your
        home, our professional and licensed builders ensure high-quality results
        that enhance the value of your property. Our team build insulated and
        non-insulated patios, completely customising each job to meet your
        unique home needs.
      </p>
      <p>
        We’ve hand-selected the best systems in Australia and use quality
        products backed with 15-year warranties. View photos from some of our
        most recent projects and discover for yourself what we can achieve for
        you.
      </p>
      <GalleryContainer>
        {data.gallery.edges.map(
          (image, i) =>
            image.node.childImageSharp && (
              <GalleryImage modal={image.node.childImageSharp.modal}>
                <Img fixed={image.node.childImageSharp.fixed} />
              </GalleryImage>
            )
        )}
      </GalleryContainer>
    </GallerySection>

    <FAQs
      flushTop={true}
      faqs={info}
      title={
        <>
          What sets <i>us</i> apart?
        </>
      }
      background={data.whyUs.childImageSharp.fluid.src}
    />

    <VideoContainer>
      <Video
        videoId="yqTGp7gMtng"
        opts={{
          height: '576',
          width: '1024',
          playerVars: {
            color: 'white',
            rel: 0
          }
        }}
      />
    </VideoContainer>

    <AboutContact style={{ margin: '0 auto', padding: 0 }} />
  </Layout>
)

export const query = graphql`
  query RoomEnclosureQuery {
    jpBackground: file(relativePath: { eq: "glass-enclosure-main.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95, toFormat: JPG) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    patiosBrisbane: file(relativePath: { eq: "gallery/enclosures/cover.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    blurredHero: file(relativePath: { eq: "blurred-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gallery: allFile(
      filter: { relativeDirectory: { eq: "gallery/enclosures" } }
    ) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
